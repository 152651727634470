<template>
<div>
    <div :id="id" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="id" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" :id="id">{{title}}</h5>
                    <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                        <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"/>
                        </svg>
                    </button>
                </div>
                <div class="modal-body">
                    <h4 class="text-danger">Before completing the adoption, please ensure all animal data has been entered</h4>
                    <h4>Enter the date <b>{{animal.animal_name}}</b> was picked up.</h4>
                    <DatePicker
                        id="outcomeDate"
                        v-model="outcomeDate"
                        :config="datePickerOptions"
                        @dp-hide="isOpen = false"
                    >
                    </DatePicker>
                    <p><small>Paid Date: {{request.paid_date | formatDate}}</small></p>
                    <div class="row" v-if="errorMsg">
                        <div class="col-lg-12 alert alert-danger text-center" role="alert">
                           {{errorMsg}}
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-white" data-dismiss="modal">Cancel</button>
                    <button 
                        v-if="!processing" 
                        type="button" 
                        class="btn text-capitalize" 
                        :class="color" 
                        :disabled="errorMsg"
                        @click="onSubmit(action)">
                        {{action}}
                    </button>
                    <button v-else type="button" class="btn text-capitalize" disabled :class="color">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {{action}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
//import DatePicker from 'vue-bootstrap-datetimepicker';  
//import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import moment from 'moment-timezone'

export default {
    name: 'ApproveDenyModal',
    components: {  },
    directives: {  },
    props: {
		id: {
			type: String,
			required: true,
		},
        title: {
			type: String,
			required: true,
		},
        action: {
			type: String,
			required: true,
		},
        color: {
			type: String,
			required: true,
		},
        adopter: {
			type: Object,
			required: true,
		},
        request: {
            type : Object,
            required : true
        },
        animal: {
            type : Object,
            required : true
        },
        index: {
			type: String,
			required: true,
		},
	},
    data() {
        return {
            outcomeDate : this.request.paid_date ? moment(this.request.paid_date) : new Date(), // this.value ? this.value : null,
            processing : false,
            datePickerOptions: {
                format: 'YYYY-MM-DD',
                useCurrent: false,
                defaultDate : new Date() //moment().endOf('day').milliseconds(0).toISOString()  //new Date(),
            },   
            error : false,
            errorMsg : null, 
        };
    },
    mounted() { },
    computed :{
        ...mapState(['user']),
    },
    methods: {
        onSubmit(action){
            this.processing = true
            let data = {}
            data.animal = {}

            data.request_id = this.request._id
            data.shelter_id = this.animal.shelter_id
            data.animal_id = this.animal._id
            data.adopter = this.adopter
            data.animal.intake_id = this.animal.intake_id
            data.animal.outcome_id = this.animal.outcome_id
            data.animal.animal_id = this.animal._id
            data.animal.animal_name = this.animal.animal_name
            data.animal.foster_id = this.animal.foster_id
            data.animal.files = this.animal.files
            data.outcome_date = moment(this.outcomeDate).endOf('day').milliseconds(0).toISOString()

            this.$store.dispatch('completeAdoption', data)
            .then(response => {
                $("#"+this.id).modal("hide");
                console.log('Modal adoption complete success', response.data)
                this.$emit('showToaster', response.data.messages)
                this.processing = false
            })
            .catch(error => {
                console.log('Modal complete adoption error', error.response)
                if(error.response && error.response.status === 400){
                    this.error = true
                    this.errorMsg = error.response && error.response.data ? 'An Error has occurred! : '+error.response.data  : 'An Error has occurred.  Please refresh and try again'
                }else{
                    $("#"+this.id).modal("hide");
                    this.$emit('showToaster', error.response.data.messages)
                }
                this.processing = false
            })
        }
    },
};
</script>

<style lang="scss" scoped>

</style>